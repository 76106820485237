import React from "react";
import bg2 from "./image/bg12.png";
import pic1 from "./image/development1.jpg";
import pic2 from "./image/development2.jpg";
import { NavLink } from "react-router-dom";
import FetchService from "./services/Fetch";
import { config } from "./config";
import { useQuery } from "@tanstack/react-query";


const Service = () => {

  const { data, isLoading } = useQuery({
    queryKey: ["services"],
    queryFn: () => FetchService.getServices(),
    keepPreviousData: true,
  });

  const { data: styleDatas = [] } = useQuery({
    queryKey: ["webStyle"],
    queryFn: () => FetchService.getStyle(),
    keepPreviousData: true,
  });
  const getCategoryStyles = (category) => {
    const matchingStyle = styleDatas?.find((style) => style.category === category);
    return matchingStyle
      ? {
        fontSize: matchingStyle.font_size,
        fontWeight: matchingStyle.font_weight,
        textAlign: matchingStyle.text_alignment?.toLowerCase(),
        color: matchingStyle.content_color,
      }
      : {};
  };



  const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };
  const developmentService = data?.Development?.[0];
  const realEstateService = data?.RealEstateConsultingService?.[0];



  const skeletonSection = (
    <div className="container md:p-2 md:mx-auto md:pt-12">
      <div className="m-6 rounded p-6 bg-gray-200 animate-pulse">
        <div className="h-6 bg-gray-300 rounded mb-4"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-8 w-32 bg-gray-300 rounded mt-4"></div>
      </div>
    </div>
  );

  const showSkeleton = isLoading || data?.length === 0;
  return (
    <>
      {/* <div className="md:pb-0"> */}
      {/* <div
          className="bg-no-repeat bg-cover h-[1100px] "
          style={{ backgroundImage: `url(${bg2})` }}
        > */}

      <div className=" w-[90%] md:w-[70%]
            mx-auto bg ">

        <div className="grid grid-cols-1 md:grid-cols-2 mt-2 gap-5 md:gap-0 mx-auto pt-2">
          {showSkeleton
            ? Array.from({ length: 4 }).map((_, idx) => (
              <React.Fragment key={idx}>{skeletonSection}</React.Fragment>
            ))
            :
            data &&
            [...(data.RealEstateConsultingService || []), ...(data.Development || [])]
              .filter(service => service.show_on_home_page === "on")
              .map(service => (
                <div className="md:w-[90%] mt-10 mx-auto border border-transparent overflow-hidden shadow-xl rounded-xl "
                >
                  <div>
                    <img class="w-full pb-5"
                      src={`${config.imagUrl}/${service.image}`}
                      alt={service.title}
                    />
                  </div>
                  <div
                    style={{
                      backgroundImage: service?.card_background_image
                        ? `url(${config.imagUrl}/${service.card_background_image})`
                        : "none",
                      backgroundColor:
                        !service?.card_background_image && service?.card_background_color
                          ? service.card_background_color
                          : "#fff",
                    }}
                    className="border h-full border-transparent bg-white text-center py-20 px-2">
                    <h3 className="text-3xl tracking-wide font-semibold capitalize pb-5"
                      style={getCategoryStyles("Title")}
                    >
                      {service.title}
                    </h3>
                    <div
                      style={getCategoryStyles("Description")}
                      className="pb-5 text-gray-600 text-base c"
                      dangerouslySetInnerHTML={{
                        __html: truncateText(service.description_1, 36),
                      }}
                    ></div>
                    <button className="border shadow-2xl mt-[-20px] hover:bg-rose-700 hover:scale-105 duration-200 border-white colour text-white py-2 px-5 rounded-full">
                      <NavLink to={`/${service.button_link}`}>Learn more</NavLink>
                    </button>
                  </div>
                </div>
              ))}
        </div>


      </div>
      {/* </div> */}
      {/* </div> */}
      {/* <div className="text-center">
          <div className="md:pt-32">
            <button className="hover:bg-rose-700 shadow-2xl width=[20%] colour hover:scale-105 duration-200 text-white rounded-xl tracking-wide px-5 py-2">
              <NavLink to="contact">Contact Us</NavLink>
            </button>
          </div>
        </div> */}
    </>
  );
};
const list = [
  {
    id: "1",
    img: pic1,
    title: "Real Estate Consulting Service",
    // head: "Lease Brokerage",
    desc: "Guardian is a verified Government leasing expert that has worked with numerous agencies including VA, GSA, DHS, DOJ, SSA, IRS, FAA, DoD and others. If you are seeking a leasing expert to guide you through the Federal lease procurement process, contract administration, dispute resolution, lease renewals,  ",
    class: "ml-10",
  },
  {
    id: "2",
    img: pic2,
    title: "Development",
    // head: "  Lease Consulting",
    class: "mt-10",
    desc: "Guardian is a certified Service-Disabled Veteran-Owned Small Business (SDVOSB) and verified through the Small Business Certification (VetCert) program (Formerly VA's - Vendor Information Pages). With this certification, Guardian has created strategic alliances with several developers, architectural",
  },
  // {
  //   img: pic3,
  //   title: "GSA & Federal",
  //   head: "Lease Assistance",
  //   desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit illo aspernatur quae repudiandae  possimus veritatis corrupti voluptatem, doloribus sed aliquid recusandae?Rem accusamus ipsam ea?",
  //   class: "rounded-tr-[100px]",
  // },
];
export default Service;
