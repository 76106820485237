import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { config } from "./config"; // Make sure config is imported correctly
import FetchService from "./services/Fetch";
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev la la-angle-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

const Hero = () => {

  const {
    data: data,
    isLoading: dataLoading,

  } = useQuery({
    queryKey: ["hero"],

    queryFn: () => FetchService.getHero(),
    keepPreviousData: true,
  });
  {
    console.log("hero==========", data);
  }

  var settings = {
    arrows: true,
    dots: true,
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider
  className="owl-slider owl-carousel owl-theme owl-none"
  {...settings}
>


  {data?.map((slide) => (
    <div
    key={slide.id}
     
      
  >
    
      <div className=" absolute bg-white/75 sm:bg-transparent sm:bg-gradient-to-r sm:from-white/95 sm:to-white/25"
      ></div>
      <div  className="relative customBackground bg-cover md:bg-center bg-no-repeat pt-20 mt-[50px] mb-20" style={{
                              backgroundImage: slide?.card_background_image
                                ? `url(${config.imagUrl}/${slide.card_background_image})`
                                : "none",
                              backgroundColor:
                                !slide?.card_background_image && slide?.card_background_color
                                  ? slide.card_background_color
                                  : "#fff",
                            }}>

      <div className="relative mx-auto pt-10 container pr-4 pl-[25px] pb-[5rem] sm:pt-0 sm:pr-6 lg:flex lg:[50vh] lg:items-center lg:pr-8">
        <div className="space-y-5 md:pt-6">
          <h3 className="text-white tracking-wider text-2xl">
          {slide.subtitle}
          </h3>
          <h2 className="text-white text-3xl uppercase font-bold tracking-wider">
          {slide.title} 
          </h2>
          
          <div className="mt-4 text-white tracking-wider max-w-lg sm:text-xl sm:leading-relaxed"
                          
                          dangerouslySetInnerHTML={{
                            __html: slide?.description_1,
                          }}
                        />
          

          <div className="!mt-[6px] md:pt-8 flex flex-wrap gap-4 text-center">
            <Link
              to={`/${slide.button_link}`}
              className="block w-full rounded border border-white px-12 py-3 font-medium text-white hover:bg-rose-700 focus:outline-none shadow-2xl focus:ring active:bg-rose-500 sm:w-auto"
            >
              {slide.button_content}
            </Link>
          </div>
        </div>
      </div>


      </div>

     
      {/* Embed Map */}
    
    </div>
  ))}
</Slider>

    </>
  );
};

export default Hero;
