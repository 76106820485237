import React,{useEffect} from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Aboutus from "./Aboutus";
import Contact from "./Contact";

import Footer from "./Footer";
import Nav from "./Layout/Nav";
import Services from "./Our Service";
import LatestNews from "./OurBlog";
import ScrollToTop from "./ScrollToTop";
import HOME from "./Gurad";
import Work from "./Company/Work"
import Security from "./Company/Security" 
import Features from "./Features"
import Project from "./Project";
import UsefulLink from "./UsefulLink";
import bg1 from "./image/bg3.jpg";
  import { ToastContainer } from "react-toastify";
      import "react-toastify/dist/ReactToastify.css";
      

const App = () => {

  const params = useLocation()



  useEffect(() => {
    if(params.pathname === '/') {
      document.title = "Guardian Real Estate And Development";  
    

    }else {

      document.title = params.pathname.split('/')[1]
    }
    console.log(params)
  }, [params]);

  return (
    <>
      <ToastContainer />
      <Nav />
      <ScrollToTop />
      <div className="bg-cover" style={{ backgroundImage: `url(${bg1})` }}>
        <Routes>
          <Route path="/" element={<HOME />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/services/:id" element={<Services />} />
          <Route path="/services" element={<Services />} />
          <Route path="/latest-news" element={<LatestNews />} />
          {/* <Route path="/project" element={<Project />} /> */}
          <Route path="/useful-link" element={<UsefulLink />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/work" element={<Work />} />
          <Route path="/security" element={<Security />} />
          <Route path="/features" element={<Features />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
};

export default App;
