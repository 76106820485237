import axios from "axios";
import { config } from "../config"; // Ensure this is pointing to the correct config file

// Create an Axios instance
const API = () => {
  const defaultOptions = {
    baseURL: config.appUrl, // Base URL from config
    timeout: 10000, // Optional: Add timeout for requests
    headers: {
      "Content-Type": "application/json", // Default header
    },
  };

  // Create an Axios instance with default options
  const instance = axios.create(defaultOptions);

  // Request Interceptor
  instance.interceptors.request.use(
    (config) => {
      // Add dynamic headers if needed (e.g., Authorization)
      const token = localStorage.getItem("token"); // Example: Get token from local storage
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      // Handle request errors
      console.error("Request error:", error);
      return Promise.reject(error);
    }
  );

  // Response Interceptor
  instance.interceptors.response.use(
    (response) => {
      // Return the response as is to avoid breaking existing behavior
      return response.data; 
    },
    (error) => {
      console.error("Response error:", error);
  
      if (error.response) {
        console.error(
          `API Error - Status: ${error.response.status}, Message: ${error.response.data.message || error.message}`
        );
        return Promise.reject({
          status: error.response.status,
          message: error.response.data.message || "An error occurred",
        });
      } else if (error.request) {
        return Promise.reject({
          message: "No response from server. Please check your network.",
        });
      } else {
        return Promise.reject({
          message: error.message || "An unknown error occurred",
        });
      }
    }
  );
  

  return instance;
};

// Export the Axios instance
export default API();
