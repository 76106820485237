
import API from '../helpers/API';

let FetchService = {};

FetchService.getHome = async (data) => {
  try {
    const response = await API.get("home-data", data ? { params: data } : undefined);
    console.log("API response:", response); // Log the full response
    if (!response || !response.data) {
      throw new Error("Invalid API response");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching home data:", error);
    throw error;
  }
};

FetchService.getHeader = async (data) => {
  try {
    const response = await API.get("fetch-header-data", data ? { params: data } : undefined);
    if (!response || !response.data) {
      throw new Error("Invalid API response");
    }
    return response.data; // Return the parsed response
  } catch (error) {
    console.error("Error fetching header data:", {
      message: error.message,
      response: error.response ? error.response.data : null,
      stack: error.stack,
    });
    throw error; // Re-throw error for upstream handling
  }
};

FetchService.getHero = async (data) => {
  try {
    const response = await API.get("hero", data ? { params: data } : undefined);
    if (!response || !response.data) {
      throw new Error("Invalid API response");
    }
    return response.data; // Return the parsed response
  } catch (error) {
    console.error("Error fetching header data:", {
      message: error.message,
      response: error.response ? error.response.data : null,
      stack: error.stack,
    });
    throw error; // Re-throw error for upstream handling
  }
};


FetchService.getServices = async (data) => {
  try {
    const response = await API.get("service", data);
    
    return response.data; // `response` is the correct reference here

    
  } catch (error) {
    console.error("Error fetching services data:", error);
    
    throw error; // If an error occurs, we throw it
  }
};



FetchService.getNews = async (data) => {
  try {
    const response = await API.get("latest-news", data);
    
    return response.data; // `response` is the correct reference here

    
  } catch (error) {
    console.error("Error fetching  data:", error);
    
    throw error; // If an error occurs, we throw it
  }
};

FetchService.getAbout = async (data) => {
  try {
    const response = await API.get("about-us", data ? { params: data } : undefined);
    if (!response || !response.data) {
      throw new Error("Invalid API response");
    }
    return response.data; // Return the parsed response
  } catch (error) {
    console.error("Error fetching header data:", {
      message: error.message,
      response: error.response ? error.response.data : null,
      stack: error.stack,
    });
    throw error; // Re-throw error for upstream handling
  }
};

FetchService.getStyle = async (data) => {
  try {
    const response = await API.get(
      "website-style",
      data ? { params: data } : undefined
    )

    if (!response || !response.data) {
      throw new Error("Invalid API response");
    }
    return response.data;
  } catch (error) {
    console.error(`Error fetching website-style with data: ${JSON.stringify(data)}`, {
      message: error.message,
      response: error.response ? error.response.data : null,
      stack: error.stack,
    });
    throw error;
  }
};
FetchService.getuseFullink = async (data) => {
  try {
    const response = await API.get(
      "useful-links",
      data ? { params: data } : undefined
    );

    if (!response || !response.data) {
      throw new Error("Invalid API response");
    }
    return response.data;
  } catch (error) {
    console.error(`Error fetching website-style with data: ${JSON.stringify(data)}`, {
      message: error.message,
      response: error.response ? error.response.data : null,
      stack: error.stack,
    });
    throw error;
  }
};
FetchService.getbanner = async (data) => {
  try {
    const response = await API.get(
      "banners",
      data ? { params: data } : undefined
    );

    if (!response || !response.data) {
      throw new Error("Invalid API response");
    }
    return response.data;
  } catch (error) {
    console.error(`Error fetching website-style with data: ${JSON.stringify(data)}`, {
      message: error.message,
      response: error.response ? error.response.data : null,
      stack: error.stack,
    });
    throw error;
  }
};

FetchService.getFooter = async (data) => {
  try {
    const response = await API.get(
      "footer",
      data ? { params: data } : undefined
    );

    if (!response || !response.data) {
      throw new Error("Invalid API response");
    }
    return response.data;
  } catch (error) {
    console.error(`Error fetching website-style with data: ${JSON.stringify(data)}`, {
      message: error.message,
      response: error.response ? error.response.data : null,
      stack: error.stack,
    });
    throw error;
  }
};

FetchService.getCta = async (data) => {
  try {
    const response = await API.get(
      "cta",
      data ? { params: data } : undefined
    );

    if (!response || !response.data) {
      throw new Error("Invalid API response");
    }
    return response.data;
  } catch (error) {
    console.error(`Error fetching website-style with data: ${JSON.stringify(data)}`, {
      message: error.message,
      response: error.response ? error.response.data : null,
      stack: error.stack,
    });
    throw error;
  }
};

FetchService.getContactUs = async (data) => {
  try {
    const response = await API.get(
      "contact",
      data ? { params: data } : undefined
    );

    if (!response || !response.data) {
      throw new Error("Invalid API response");
    }
    return response.data;
  } catch (error) {
    console.error(`Error fetching website-style with data: ${JSON.stringify(data)}`, {
      message: error.message,
      response: error.response ? error.response.data : null,
      stack: error.stack,
    });
    throw error;
  }
};
export default FetchService;
