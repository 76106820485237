import React from "react";
import PageTitle from "./PageTitle";
import bnr5 from "./image/bg3.jpg";
import { useQuery } from "@tanstack/react-query";
import FetchService from "./services/Fetch";
import { config } from "./config";
import { Link } from "react-router-dom";

const Aboutus = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["about"],
    queryFn: () => FetchService.getAbout(),
    keepPreviousData: true,
  });

  const { data: bannerData = [] } = useQuery({
    queryKey: ["banner"],
    queryFn: FetchService.getbanner,
    keepPreviousData: true,
  });


  const { data: styleDatas = [] } = useQuery({
    queryKey: ["webStyle"],
    queryFn: () => FetchService.getStyle(),
    keepPreviousData: true,
  });


  const getCategoryStyles = (category) => {
    const matchingStyle = styleDatas?.find((style) => style.category === category);
    return matchingStyle
      ? {
        fontSize: matchingStyle.font_size,
        fontWeight: matchingStyle.font_weight,
        textAlign: matchingStyle.text_alignment?.toLowerCase(),
        color: matchingStyle.content_color,
      }
      : {};
  };



  const skeletonSection = (
    <div className="container md:p-2 md:mx-auto md:pt-12">
      <div className="m-6 rounded p-6 bg-gray-200 animate-pulse">
        <div className="h-6 bg-gray-300 rounded mb-4"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-8 w-32 bg-gray-300 rounded mt-4"></div>
      </div>
    </div>
  );

  const showSkeleton = isLoading || data?.length === 0;
  const aboutBanner = bannerData?.find(banner => banner.type === "About Us");


  return (
    <>
      {/* Header Section */}
      <div
        className="dlab-bnr-inr overlay-primary bg-no-repeat bg-cover h-64" // Example with custom height
        style={{
          backgroundImage: aboutBanner?.card_background_image
            ? `url(${config.imagUrl}/${aboutBanner.card_background_image})`
            : "none",
          backgroundColor: aboutBanner?.card_background_color || "transparent",
        }}
      >
        <PageTitle motherMenu={aboutBanner?.heading} activeMenu="Services" />
      </div>



        <div  style={{
                  backgroundImage: data?.[0]?.background_image
                    ? `url(${config.imagUrl}/${data[0].background_image})`
                    : "none",
                  backgroundColor: !data?.[0]?.background_image && data?.[0]?.background_color
                    ? data[0].background_color
                    : "transparent",
                }}
                  className="md:flex space-y-4  flex-col justify-center bg-center h-auto bg-cover bg-no-repeat">

      {/* Main Content Section */}

      <div
        style={{
          backgroundImage: data?.[0]?.background_image
            ? `url(${config.imagUrl}/${data[0].background_image})`
            : "none",
          backgroundColor: !data?.[0]?.background_image && data?.[0]?.background_color
            ? data[0].background_color
            : "transparent",
        }}
        className="bg-no-repeat bg-cover"
      >
        {showSkeleton
          ? Array.from({ length: 3 }).map((_, idx) => (
            <React.Fragment key={idx}>{skeletonSection}</React.Fragment>
          ))
          : data?.map((aboutData) => {
            if (aboutData?.status !== "on") return null;

            const imageAlignmentClass =
              aboutData?.content_alignment === "right" ? "md:flex-row-reverse" : "md:flex-row";

            return (
              <div key={aboutData.id}>
                <div className="container md:p-2 md:mx-auto md:pt-12">
                  <div
                    className="m-6 rounded p-6 bg-no-repeat bg-cover"
                    style={{
                      backgroundImage: aboutData?.card_background_image
                        ? `url(${config.imagUrl}/${aboutData.card_background_image})`
                        : "none",
                      backgroundColor:
                        !aboutData?.card_background_image && aboutData?.card_background_color
                          ? aboutData.card_background_color
                          : "transparent",
                    }}
                  >
                    <div
                      className={`flex text-[19px] items-center m-4 md:m-0 mt-8 md:mt-4 ${aboutData?.image ? imageAlignmentClass : "flex-col"
                        }`}
                    >
                      {aboutData?.image && (
                        <img
                          src={`${config.imagUrl}/${aboutData?.image}`}
                          alt="About_Image"
                          className="m-4 md:m-0 md:mr-6 md:mb-4 sm:w-[20px] sm:h-[20px] md:w-[23%] md:h-[16%] rounded-3xl drop-shadow-2xl"
                        />
                      )}
                      <div className={`${aboutData?.image ? "md:w-3/4" : "w-full"}`}>
                        <h1
                          style={getCategoryStyles("Title")}

                          className="text-3xl font-semibold mb-5 mt-7"
                        >
                          {aboutData?.title}
                        </h1>
                        <h3
                          style={getCategoryStyles("Subtitle")}
                          className="text-2xl font-semibold mb-5 mt-7"
                        >
                          {aboutData?.subtitle}
                        </h3>
                        <div
                          style={getCategoryStyles("Description")}
                          dangerouslySetInnerHTML={{
                            __html: aboutData?.description_1,
                          }}
                        />
                      </div>
                    </div>
                    <p
                      className="m-4 md:m-0 text-[20px]"
                      style={getCategoryStyles("Description")}
                      dangerouslySetInnerHTML={{
                        __html: aboutData?.description_2,
                      }}
                    ></p>
                    <div className="text-center md:mt-4 mb-4 md:mb-8">
                      {aboutData?.button_link && aboutData?.button_content && (
                        <Link
                          to={`/` + aboutData.button_link} 
                          className="colour text-white text-xl py-2 text-center px-3 hover:scale-105 hover:bg-rose-700 duration-150 rounded-xl shadow-2xl"
                        >
                          {aboutData.button_content}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      </div>

  
      
    </>
  );
};

export default Aboutus;
