const completeConfig = {
  production: {
    appUrl: process.env.REACT_APP_API_URL || "https://vcanaglobal.in/guardian/api/", // fallback URL
    imagUrl: process.env.REACT_APP_IMAGE_URL || "https://vcanaglobal.in/guardian", // iMAGE URL
  },
  default: {
    appUrl: process.env.REACT_APP_API_URL || "https://vcanaglobal.in/guardian/api/", // fallback URL
    imagUrl: process.env.REACT_APP_IMAGE_URL || "https://vcanaglobal.in/guardian", // iMAGE URL
  }
};

const config = completeConfig[process.env.NODE_ENV] || completeConfig.default;

export default config;
