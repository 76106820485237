import React from "react";
// import { Link } from 'react-router-dom'
import PageTitle from "./PageTitle";
import bnr5 from "./image/bg3.jpg";
import { useQuery } from "@tanstack/react-query";
import FetchService from "./services/Fetch";
import { config } from "./config";
import { Link } from "react-router-dom";




const UsefulLink = () => {

  const { data, isLoading } = useQuery({
    queryKey: ["about"],
    queryFn: () => FetchService.getuseFullink(),
    keepPreviousData: true,
  });

  const { data: styleDatas = [] } = useQuery({
    queryKey: ["webStyle"],
    queryFn: () => FetchService.getStyle(),
    keepPreviousData: true,
  });
  const { data: bannerData = [] } = useQuery({
    queryKey: ["banner"],
    queryFn: FetchService.getbanner,
    keepPreviousData: true,
  });
  const getCategoryStyles = (category) => {
    const matchingStyle = styleDatas?.find((style) => style.category === category);
    return matchingStyle
      ? {
        fontSize: matchingStyle.font_size,
        fontWeight: matchingStyle.font_weight,
        textAlign: matchingStyle.text_alignment?.toLowerCase(),
        color: matchingStyle.content_color,
      }
      : {};
  };

  const skeletonSection = (
    <div className="container md:p-2 md:mx-auto md:pt-12">
      <div className="m-6 rounded p-6 bg-gray-200 animate-pulse">
        <div className="h-6 bg-gray-300 rounded mb-4"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-8 w-32 bg-gray-300 rounded mt-4"></div>
      </div>
    </div>
  );

  const showSkeleton = isLoading || data?.length === 0;
  const aboutBanner = bannerData?.find(banner => banner.type === "Useful Links");

  return (
    <>
      <div className="">
        {/* <!-- inner page banner --> */}
        <div
          className="dlab-bnr-inr overlay-primary bg-no-repeat bg-cover h-64" // Example with custom height
          style={{
            backgroundImage: aboutBanner?.card_background_image
              ? `url(${config.imagUrl}/${aboutBanner.card_background_image})`
              : "none",
            backgroundColor: aboutBanner?.card_background_color || "transparent",
          }}
        >
          <PageTitle motherMenu={aboutBanner?.heading} activeMenu="Useful Links" />
        </div>
        <div style={{
          backgroundImage: data?.[0]?.background_image
            ? `url(${config.imagUrl}/${data[0].background_image})`
            : "none",
          backgroundColor: !data?.[0]?.background_image && data?.[0]?.background_color
            ? data[0].background_color
            : "transparent",
        }}
          className="md:flex space-y-4 pb-20 flex-col justify-center bg-center h-auto bg-cover bg-no-repeat"
        // style={{ backgroundImage: `url(${bg2})` }}
        >
          <div class="relative z-10 pt-10 pb-10 px-4 ">
            <div class="md:max-w-4xl mx-auto container ">
              <h2 class="mb-16 text-2xl md:text-8xl text underline text-white xl:text-3xl text-center font-bold font-heading tracking-px-n leading-none" style={{ color: 'white' }}>
                The System for Award Management (SAM.gov)
              </h2>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4  mx-auto container ">
              {
                showSkeleton
                  ? Array.from({ length: 3 }).map((_, idx) => (
                    <React.Fragment key={idx}>{skeletonSection}</React.Fragment>
                  ))
                  : data?.map((listing) => {
                    const pointersArray = Array.isArray(listing.pointers)
                      ? listing.pointers
                      : Object.values(listing.pointers || {});
                    return (
                      <div key={listing.id} >

                        <div style={{
                          backgroundImage: listing?.card_background_image
                            ? `url(${config.imagUrl}/${listing.card_background_image})`
                            : "none",
                          backgroundColor:
                            !listing?.card_background_image && listing?.card_background_color
                              ? listing.card_background_color
                              : "transparent",
                        }} class="bg-[#eaf4fc] rounded-lg shadow-lg p-6">
                          <h2 class="text-2xl text-center font-bold mb-4" style={getCategoryStyles("Title")}>{listing.subtitle}</h2>
                          <ul class="list-disc ml-6 flex flex-wrap">
                            {pointersArray.map((list) => (
                              <li class="mb-2 sm:w-1/2" key={list.name}>
                                <a
                                  href={list.url}
                                  target="_blank"
                                  class="text-blue-600 hover:underline"
                                  rel="noreferrer"
                                  style={getCategoryStyles("Description")}
                                >
                                  {list.name}
                                </a>
                              </li>
                            ))}
                          </ul>

                        </div>
                      </div>
                    );
                  })}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const VAData = [
  {
    id: "1",
    title: "Office of Construction & Facilities Management",
    link: "https://www.cfm.va.gov/index.asp",
  },
  {
    id: "2",
    title: "Building Requirements for Leased Space",
    link: "https://www.cfm.va.gov/index.asp",
  },
  {
    id: "3",
    title: "VA - Design Criteria",
    link: "https://www.cfm.va.gov/til/criteria.asp",
  },
  {
    id: "4",
    title: "VA Enhanced-Use Lease Program",
    link: "https://www.cfm.va.gov/til/criteria.asp",
  },
];

const GSAData = [
  {
    title: "Real Estate",
    link: "https://www.gsa.gov/real-estate?topnav=",
  },
  {
    title: "Leasing Overview",
    link: "https://www.gsa.gov/real-estate/real-estate-services/leasing-overview?gsaredirect=leasing",
  },
  {
    title: "Leasing Desk Guide",
    link: "https://www.gsa.gov/real-estate/real-estate-services/leasing/leasing-policy",
  },
  {
    title: "Inventory of owned and Leased Property - Map Tool",
    link: "https://www.iolp.gsa.gov/iolp/",
  },
];
export default UsefulLink;
