import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';

const ServiceDropdown = ({ data, children }) => {
  return (
    <div className="relative group">
      {/* Main menu item */}
      <span className="cursor-pointer">{children}</span>

      {/* Dropdown menu */}
      <div className="absolute hidden group-hover:block bg-white shadow-lg">
        <ul className="py-2">
          {data.map((child) => (
            <li key={child.id}>
              <NavLink
                to={`/${child.link || "#"}`} // Ensure link is valid
                className="block px-4 py-2 hover:bg-gray-200"
              >
                {child.category}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};


export default ServiceDropdown