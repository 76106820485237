import React, { useState } from "react";
import PageTitle from "./PageTitle";
import bnr5 from "./image/bg3.jpg";
import { useParams, NavLink } from "react-router-dom";
import pic2 from "./image/development2.jpg";
import { useQuery } from "@tanstack/react-query";
import FetchService from "./services/Fetch";
import { config } from "./config";

const OurService = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["services"],
    queryFn: () => FetchService.getServices(),
    keepPreviousData: true,
  });

  const { data: datacta = [] } = useQuery({
    queryKey: ["cta"],
    queryFn: () => FetchService.getCta(),
    keepPreviousData: true,
  });


  const { data: styleDatas = [] } = useQuery({
    queryKey: ["webStyle"],
    queryFn: () => FetchService.getStyle(),
    keepPreviousData: true,
  });
  const { data: bannerData = [] } = useQuery({
    queryKey: ["banner"],
    queryFn: FetchService.getbanner,
    keepPreviousData: true,
  });
  const getCategoryStyles = (category) => {
    const matchingStyle = styleDatas?.find((style) => style.category === category);
    return matchingStyle
      ? {
        fontSize: matchingStyle.font_size,
        fontWeight: matchingStyle.font_weight,
        textAlign: matchingStyle.text_alignment?.toLowerCase(),
        color: matchingStyle.content_color,
      }
      : {};
  };



  const developmentService = data?.Development;
  const realEstateService = data?.RealEstateConsultingService;
  const aboutBanner = bannerData?.find(banner => banner.type === "Services");

  const serviceCtaDEV = datacta?.find(cta => cta.cta_type === "Development");
  const serviceCTaRel = datacta?.find(cta => cta.cta_type === "Real Estate Consulting Service");

  let show1 = true;
  let show2 = true;

  const params = useParams();
  const id = params.id;

  if (id === "1") {
    show2 = false;
  }
  if (id === "2") {
    show1 = false;
  }
  const skeletonSection = (
    <div className="container md:p-2 md:mx-auto md:pt-12">
      <div className="m-6 rounded p-6 bg-gray-200 animate-pulse">
        <div className="h-6 bg-gray-300 rounded mb-4"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-8 w-32 bg-gray-300 rounded mt-4"></div>
      </div>
    </div>
  );

  const showSkeleton = isLoading || data?.Development.length === 0;
  const showSkeletonw = isLoading || data?.RealEstateConsultingService === 0;


  const ctaData = id === "1" ? serviceCTaRel : serviceCtaDEV; // Conditionally set the CTA data based on the id
  return (
    <div>
      {/* Inner page banner */}
      <div
        className="dlab-bnr-inr overlay-primary bg-no-repeat bg-cover h-64" // Example with custom height
        style={{
          backgroundImage: aboutBanner?.card_background_image
            ? `url(${config.imagUrl}/${aboutBanner.card_background_image})`
            : "none",
          backgroundColor: aboutBanner?.card_background_color || "transparent",
        }}
      >
        <PageTitle motherMenu={aboutBanner?.heading} activeMenu="Services" />
      </div>

      <div
        className="bg-cover bg-no-repeat py-20"
        style={{
          backgroundImage: data?.Development?.[0]?.background_image
            ? `url(${config.imagUrl}/${data.Development[0].background_image})`
            : "none",
          backgroundColor: !data?.Development?.[0]?.background_image && data?.Development?.[0]?.background_color
            ? data.Development[0].background_color
            : "transparent",
        }}
      >
        {showSkeleton
          ? Array.from({ length: 3 }).map((_, idx) => (
            <React.Fragment key={idx}>{skeletonSection}</React.Fragment>
          )) :


          show1 && realEstateService?.length > 0 ? (
            realEstateService
              .filter((service) => service.status === "on")
              .map((service) => {
                const imageAlignmentClass =
                  service?.content_alignment === "right" ? "md:flex-row-reverse" : "md:flex-row";

                return (
                  <div
                    key={service.id}
                    className="p-2 container mx-auto border my-10 m-2 hover:scale-105 duration-200 border-transparent rounded overflow-hidden shadow-xl bg-cover bg-no-repeat py-20"
                    style={{
                      backgroundImage: service?.card_background_image
                        ? `url(${config.imagUrl}/${service.card_background_image})`
                        : "none",
                      backgroundColor: !service?.card_background_image && service?.card_background_color
                        ? service.card_background_color
                        : "#fff",
                    }}
                  >
                    <div
                      className={`flex text-[19px] items-center m-4 md:m-0 mt-8 md:mt-4 ${service?.image ? imageAlignmentClass : "flex-col"}`}
                    >
                      {service?.image && (
                        <img
                          src={`${config.imagUrl}/${service.image}`}
                          alt="About_Image"
                          className="m-4 md:m-0 md:mr-6 md:mb-4 sm:w-[20px] sm:h-[20px] md:w-[23%] md:h-[16%] rounded-3xl drop-shadow-2xl"
                        />
                      )}
                      <div className={`${service?.image ? "md:w-3/4" : "w-full"}`}>
                        <h2
                          className="text text-5xl text-left font-bold pb-5"
                          style={getCategoryStyles("Title")}
                        >
                          {service.title}
                        </h2>
                        <div
                          style={getCategoryStyles("Description")}
                          dangerouslySetInnerHTML={{ __html: service.description_1 }}
                        />
                        <h1
                          className="text-3xl font-semibold mb-5 mt-7"
                          style={getCategoryStyles("Subtitle")}
                        >
                          {service.subtitle}
                        </h1>
                        <div
                          className="mb-5 text-[18px]"
                          style={getCategoryStyles("Description")}
                          dangerouslySetInnerHTML={{ __html: service.description_2 }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            show1 && <div>{skeletonSection}</div>
          )}


        {
          showSkeletonw
            ? Array.from({ length: 3 }).map((_, idx) => (
              <React.Fragment key={idx}>{skeletonSection}</React.Fragment>
            )) :
            show2 && developmentService?.length > 0 ? (
              developmentService
                .filter((service) => service.status === "on")
                .map((service) => {
                  const imageAlignmentClass =
                    service?.content_alignment === "right" ? "md:flex-row-reverse" : "md:flex-row";

                  return (
                    <div
                      key={service.id}
                      className="p-2 container mx-auto border my-10 m-2 hover:scale-105 duration-200 border-transparent rounded overflow-hidden shadow-xl bg-cover bg-no-repeat py-20"
                      style={{
                        backgroundImage: service?.card_background_image
                          ? `url(${config.imagUrl}/${service.card_background_image})`
                          : "none",
                        backgroundColor: !service?.card_background_image && service?.card_background_color
                          ? service.card_background_color
                          : "#fff",
                      }}
                    >
                      <div
                        className={`flex text-[19px] items-center m-4 md:m-0 mt-8 md:mt-4 ${service?.image ? imageAlignmentClass : "flex-col"}`}
                      >
                        {service?.image && (
                          <img
                            src={`${config.imagUrl}/${service.image}`}
                            alt="About_Image"
                            className="m-4 md:m-0 md:mr-6 md:mb-4 sm:w-[20px] sm:h-[20px] md:w-[23%] md:h-[16%] rounded-3xl drop-shadow-2xl"
                          />
                        )}
                        <div className={`${service?.image ? "md:w-3/4" : "w-full"}`}>
                          <h2
                            className="text text-5xl text-left font-bold pb-5"
                            style={getCategoryStyles("Title")}
                          >
                            {service.title}
                          </h2>
                          <div
                            style={getCategoryStyles("Description")}
                            dangerouslySetInnerHTML={{ __html: service.description_1 }}
                          />
                          <h1
                            className="text-3xl font-semibold mb-5 mt-7"
                            style={getCategoryStyles("Subtitle")}
                          >
                            {service.subtitle}
                          </h1>
                          <div
                            className="mb-5 text-[18px]"
                            style={getCategoryStyles("Description")}
                            dangerouslySetInnerHTML={{ __html: service.description_2 }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              show2 && <div>{skeletonSection}</div>
            )}

        {/* {show2 && (
          <div className="p-2 container mx-auto md:pt-16 md:pb-20 md:pl-10 border my-10 m-2 hover:scale-105 duration-200 border-transparent rounded overflow-hidden shadow-xl bg-white">
            <div className="mx-2">
              <h2 className="text text-5xl text-left font-bold pb-5">Development</h2>
              <div className="md:flex justify-between md:space-x-16">
                <div className="text-[18px]">
                  <p>
                    Guardian is a certified Service-Disabled Veteran-Owned Small
                    Business (SDVOSB) and verified through the Small Business
                    Certification (VetCert) program (Formerly VA's - Vendor
                    Information Pages).
                  </p>
                  <p className="text-[18px] mt-2">
                    Guardian Development Group LLC maintains an active SAM.gov
                    registration...
                  </p>
                </div>
                <img className="md:h-96 md:mt-0 md:w-96" src={pic2} alt="Development" />
              </div>
            </div>
          </div>
        )} */}



        <div className="w-90%">
          <div
            className="bg-cover bg-center mb-10 py-20 mx-auto rounded w-[80%] px-10 md:px-20"
            style={{
              backgroundImage: ctaData?.background_image
                ? `url(${config.imagUrl}/${ctaData.background_image})`
                : "none",
              backgroundColor: !ctaData?.background_image && ctaData?.background_color ? ctaData?.background_color : "#fff",
            }}
          >
            <div className="max-w-5xl mx-auto text-center">
              <h2 className="text-2xl font-bold text-[black] mb-4">
                {ctaData?.title}
              </h2>
              <div className="inline-block bg-[#67a2d7] hover:bg-[#5ba8eb] text-white mt-2 py-3 px-6 rounded">
                <NavLink to={`/` + ctaData?.button_link || "/contact"}>{ctaData?.button_content}</NavLink>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default OurService;

