import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"; // Import these from react-query
import "./index.css";
import App from "./App";

// Create a QueryClient instance
const queryClient = new QueryClient();

// Create root
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}> {/* Wrap in QueryClientProvider */}
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
