import React from "react";
import PageTitle from "./PageTitle";
import bnr5 from "./image/bg3.jpg";
import Iframe from "react-iframe";
import ContactForm from "./shared/ContactForm";
import { useQuery } from "@tanstack/react-query";
import FetchService from "./services/Fetch";
import { config } from "./config";
const Contact = () => {

   const { data: bannerData = [] } = useQuery({
      queryKey: ["banner"],
      queryFn: FetchService.getbanner,
      keepPreviousData: true,
    });
    const aboutBanner = bannerData?.find(banner => banner.type === "Contact us");

    

  const { data } = useQuery({
    queryKey: ["services"],
    queryFn: () => FetchService.getContactUs(),
    keepPreviousData: true,
  });


  const contactData = data?.[0] || {};
  
  return (
    <>
        <div
        className="dlab-bnr-inr overlay-primary bg-no-repeat bg-cover h-64" // Example with custom height
        style={{
          backgroundImage: aboutBanner?.card_background_image
            ? `url(${config.imagUrl}/${aboutBanner.card_background_image})`
            : "none",
          backgroundColor: aboutBanner?.card_background_color || "transparent",
        }}
      >
        <PageTitle motherMenu={aboutBanner?.heading} activeMenu="Services" />
      </div>



      <div
          style={{
                    backgroundImage: data?.[0]?.background_image
                      ? `url(${config.imagUrl}/${data[0].background_image})`
                      : "none",
                    backgroundColor: !data?.[0]?.background_image && data?.[0]?.background_color
                      ? data[0].background_color
                      : "transparent",
                  }}
                  className="bg-no-repeat bg-cover pb-20 pt-2"
      
      
      >
      <ContactForm />
      <div className="md:pb-10">
        {/* You can uncomment the iframe below if needed */}
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d789619.5624107575!2d-104.957934!3d39.368657999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c9bd24ae3d899%3A0xe07585b7f3fdc1e4!2sDenver%20Metropolitan%20Area%2C%20CO%2C%20USA!5e0!3m2!1sen!2sin!4v1676025367829!5m2!1sen!2sin"
          width="600"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> */}
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d789619.5624107575!2d-104.957934!3d39.368657999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c9bd24ae3d899%3A0xe07585b7f3fdc1e4!2sDenver%20Metropolitan%20Area%2C%20CO%2C%20USA!5e0!3m2!1sen!2sin!4v1676025367829!5m2!1sen!2sin"
          className="border:0 w-full h-96"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          display="block"
          position="relative"
        />
      </div>

      </div>
     
    </>
  );
};

export default Contact;
