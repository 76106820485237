import React from "react";
import { useQuery } from "@tanstack/react-query";
import FetchService from "./services/Fetch";
import { config } from "./config";
import { Link } from "react-router-dom";
// import Join from "./Join.jsx";
// import { AiOutlineArrowRight } from "react-icons/ai";
import PageTitle from "./PageTitle";
import bnr5 from "./image/bg3.jpg";



//
const OurBlog = () => {


  const { data, isLoading } = useQuery({
    queryKey: ["news"],
    queryFn: () => FetchService.getNews(),
    keepPreviousData: true,
  });

  const { data: styleDatas = [] } = useQuery({
    queryKey: ["webStyle"],
    queryFn: () => FetchService.getStyle(),
    keepPreviousData: true,
  });
  console.log("about=====", data)

    const { data: bannerData = [] } = useQuery({
      queryKey: ["banner"],
      queryFn: FetchService.getbanner,
      keepPreviousData: true,
    });
    const aboutBanner = bannerData?.find(banner => banner.type === "Latest News");
  const getCategoryStyles = (category) => {
    const matchingStyle = styleDatas?.find((style) => style.category === category);
    return matchingStyle
      ? {
        fontSize: matchingStyle.font_size,
        fontWeight: matchingStyle.font_weight,
        textAlign: matchingStyle.text_alignment?.toLowerCase(),
        color: matchingStyle.content_color,
      }
      : {};
  };

  console.log("styleDatasaaas:", styleDatas);


  const skeletonSection = (
    <div className="container md:p-2 md:mx-auto md:pt-12">
      <div className="m-6 rounded p-6 bg-gray-200 animate-pulse">
        <div className="h-6 bg-gray-300 rounded mb-4"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-8 w-32 bg-gray-300 rounded mt-4"></div>
      </div>
    </div>
  );

  const showSkeleton = isLoading || data?.length === 0;
  return (
    <>
      <div className="">
        {/* <!-- inner page banner --> */}
        <div
        className="dlab-bnr-inr overlay-primary bg-no-repeat bg-cover h-64" // Example with custom height
        style={{
          backgroundImage: aboutBanner?.card_background_image
            ? `url(${config.imagUrl}/${aboutBanner.card_background_image})`
            : "none",
          backgroundColor: aboutBanner?.card_background_color || "transparent",
        }}
      >
        <PageTitle motherMenu={aboutBanner?.heading} activeMenu="Latest News" />
      </div>
        <div className="md:pt-12 pt-2 bg-no-repeat bg-cover"
          style={{
            backgroundImage: data?.[0]?.background_image
              ? `url(${config.imagUrl}/${data[0].background_image})`
              : "none",
            backgroundColor: !data?.[0]?.background_image && data?.[0]?.background_color
              ? data[0].background_color
              : "transparent",
          }}
        >
          <div className="">
          
            <div className="container mx-auto pb-20">
              {showSkeleton
                ? Array.from({ length: 3 }).map((_, idx) => (
                  <React.Fragment key={idx}>{skeletonSection}</React.Fragment>
                ))
                : data?.filter(service => service?.show_on_website == "on").map((e) => {
                  return (
                    <div className="border my-10 m-2 hover:scale-105 duration-200 border-transparent bg-no-repeat bg-cover rounded overflow-hidden shadow-xl bg-white "
                    style={{
                      backgroundImage: e?.card_background_image
                        ? `url(${config.imagUrl}/${e.card_background_image})`
                        : "none",
                      backgroundColor:
                        !e?.card_background_image && e?.card_background_color
                          ? e.card_background_color
                          : "white",
                    }}
                    
                    >
                      <div className="flex justify-center flex-col sm:flex-row mx-4 mt-2 md:mt-4 ">
                        <img
                          className="md:p-5 sm:h-[348px] sm:w-[500px]"
                          src={`${config.imagUrl}/${e.image}`}
                          alt=""
                        />
                        <div className="container md:mx-auto text-center sm:text-left">
                          <h3 className=" text-4xl mt-4 text-left hover:underline cursor-pointer font-semibold" style={getCategoryStyles("Title")}>
                            {e.title}
                          </h3>
                          
                          <div className="flex font-extralight  sm:pr-3 text-left mt-4 text-lg"
                            style={getCategoryStyles("Description")}
                            dangerouslySetInnerHTML={{
                              __html: e.description_1,
                            }}
                          />
                            <a href={`${e.button_link}`} target="_blank" rel="noreferrer">
                              <button className ="font-semibold text hover:text-rose-500 text-lg hover:scale-105 duration-200">
                                Read More
                              </button>
                            </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* <Join /> */}
      </div>
    </>
  );
};


export default OurBlog;
