import React from "react";
import { useQuery } from "@tanstack/react-query";
import FetchService from "./services/Fetch";
import { config } from "./config";
import Hero from "./Hero";
import Service from "./Service";
import Blog from "./Blog";
import { Link } from "react-router-dom";
import ContactButton from "./shared/ContactButton";
const Gurad = () => {


  const { data, isLoading, error } = useQuery({
    queryKey: ["home"],
    queryFn: () => FetchService.getHome(),
    keepPreviousData: true,
  });

  if (error) {
    console.error("Error in useQuery:", error);
  }

    const { data: bckgroundData = [] } = useQuery({
      queryKey: ["hero"],
      queryFn: FetchService.getHero,
      keepPreviousData: true,
    });
 

  const { data: styleDatas = [] } = useQuery({
    queryKey: ["webStyle"],
    queryFn: () => FetchService.getStyle(),
    keepPreviousData: true,
  });

  console.log("home======", data)

  const getCategoryStyles = (category) => {
    const matchingStyle = styleDatas?.find((style) => style.category === category);
    return matchingStyle
      ? {
        fontSize: matchingStyle.font_size,
        fontWeight: matchingStyle.font_weight,
        textAlign: matchingStyle.text_alignment?.toLowerCase(),
        color: matchingStyle.content_color,
      }
      : {};
  };

  console.log("styleDatas:", styleDatas);




  const skeletonSection = (
    <div className="container md:p-2 md:mx-auto md:pt-12">
      <div className="m-6 rounded p-6 bg-gray-200 animate-pulse">
        <div className="h-6 bg-gray-300 rounded mb-4"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-8 w-32 bg-gray-300 rounded mt-4"></div>
      </div>
    </div>
  );

  const showSkeleton = isLoading || data?.length === 0;
  return (
    <>
       <div
        style={{
          backgroundImage: bckgroundData?.[0]?.background_image
            ? `url(${config.imagUrl}/${bckgroundData[0].background_image})`
            : "none",
          backgroundColor: !bckgroundData?.[0]?.background_image && bckgroundData?.[0]?.background_color
            ? bckgroundData[0].background_color
            : "transparent",
        }}
        className="bg-no-repeat bg-cover pb-20"
      >

      <Hero />
      {/* <About /> */}
   
        <div class="flex md:mb-[-75px] flex-col justify-start container mx-auto md:flex-row">
          <div className="md:w-[73%]">
            <h2 className="text-center mx-auto text-white text-4xl  font-bold pb-10 pt-5 ">
              Services
            </h2>
          </div>
        </div>

        <div className="flex  flex-col container mx-auto md:flex-row">
          <Service />

          <Blog />
        </div>
        {/* <Join /> */}
        <ContactButton />

        {/* Main Content Section */}
        <div>



          {data?.map((aboutData) => {


            if (aboutData?.show_on_website !== "on") return null;

            const imageAlignmentClass =
              aboutData?.content_alignment === "right" ? "md:flex-row-reverse" : "md:flex-row";

            return (
              <div key={aboutData.id}>
                <div className="container md:p-2 md:mx-auto md:pt-12">
                  <div
                    className="m-6 rounded p-6 bg-no-repeat bg-cover"
                    style={{
                      backgroundImage: aboutData?.card_background_image
                        ? `url(${config.imagUrl}/${aboutData.card_background_image})`
                        : "none",
                      backgroundColor:
                        !aboutData?.card_background_image && aboutData?.card_background_color
                          ? aboutData.card_background_color
                          : "transparent",
                    }}
                  >
                    <div
                      className={`flex text-[19px] items-center m-4 md:m-0 mt-8 md:mt-4 ${aboutData?.image ? imageAlignmentClass : "flex-col"
                        }`}
                    >
                      {aboutData?.image && (
                        <img
                          src={`${config.imagUrl}/${aboutData?.image}`}
                          alt="About_Image"
                          className="m-4 md:m-0 md:mr-6 md:mb-4 sm:w-[20px] sm:h-[20px] md:w-[23%] md:h-[16%] rounded-3xl drop-shadow-2xl"
                        />
                      )}
                      <div className={`${aboutData?.image ? "md:w-3/4" : "w-full"}`}>
                        <h1
                          style={(() => {
                            const styles = getCategoryStyles("Title");
                            console.log("Title styles:", styles); // Log the styles
                            return styles;
                          })()}
                          className="text-3xl font-semibold mb-5 mt-7"
                        >
                          {aboutData?.title}
                        </h1>
                        <h3
                          style={getCategoryStyles("Subtitle")}
                          className="text-2xl font-semibold mb-5 mt-7"
                        >
                          {aboutData?.subtitle}
                        </h3>
                        <div
                          style={getCategoryStyles("Description")}
                          dangerouslySetInnerHTML={{
                            __html: aboutData?.description_1,
                          }}
                        />
                      </div>
                    </div>
                    <p
                      className="m-4 md:m-0 text-[20px]"
                      style={getCategoryStyles("Description")}
                      dangerouslySetInnerHTML={{
                        __html: aboutData?.description_2,
                      }}
                    ></p>
                    <div className="text-center md:mt-4 mb-4 md:mb-8">
                      {aboutData?.button_link && aboutData?.button_content && (
                        <Link
                          to={aboutData.button_link}
                          className="colour text-white text-xl py-2 text-center px-3 hover:scale-105 hover:bg-rose-700 duration-150 rounded-xl shadow-2xl"
                        >
                          {aboutData.button_content}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>




    </>
  );
};

export default Gurad;
