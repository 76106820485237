import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import ServiceDropdown from "../ServiceDropdown";
import FetchService from "../services/Fetch";
import { useQuery } from "@tanstack/react-query";
import pic from "../pic/g1.png";

const Nav = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["header"],
    queryFn: FetchService.getHeader,
    keepPreviousData: true,
  });

  const toggleMenu = () => setMenu(!menu);

  // Show loading or error if needed
  const skeletonSection = (
    <div className="container md:p-2 md:mx-auto md:pt-12">
      <div className="m-6 rounded p-6 bg-gray-200 animate-pulse">
        <div className="h-6 bg-gray-300 rounded mb-4"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-8 w-32 bg-gray-300 rounded mt-4"></div>
      </div>
    </div>
  );
  const showSkeleton = isLoading || data?.length === 0;



  if (isError || !Array.isArray(data)) return <div>Error loading menu</div>;

  return (
    <>
      <div className="bg-white menuNav">
        <div className="fixed top-0 w-full bg-white px-4 z-50">
          <div className="container mx-auto flex justify-between items-center">
            {/* Logo */}
            <NavLink to="/">
              <img src={pic} alt="Logo" className="h-20" />
            </NavLink>

            {/* Desktop Navigation */}
            <ul className="hidden md:flex items-center space-x-16 font-semibold text-lg">
              {data.map((item) => (
                <li key={item.id}>
                  {item.children.length > 0 ? (
                    <ServiceDropdown data={item.children}>
                      {item.category}
                    </ServiceDropdown>
                  ) : (
                    <NavLink
                      to={`/${item.link}`}
                      className={({ isActive }) =>
                        isActive ? "border-b-2 border-blue-700" : "hover:text-blue-700"
                      }
                    >
                      {item.category}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>

            {/* Mobile Menu Button */}
            <button onClick={toggleMenu} className="md:hidden">
              <AiOutlineMenu className="text-2xl" />
            </button>
          </div>
        </div>

        {/* Mobile Sidebar */}
        <div
          className={`fixed top-0 left-0 w-72 bg-white h-full z-50 transition-transform ${
            menu ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex justify-between items-center p-4 border-b">
            <img src={pic} alt="Logo" className="w-32" />
            <MdOutlineClose
              onClick={toggleMenu}
              className="text-2xl cursor-pointer"
            />
          </div>
          <ul className="space-y-4 p-4">
            {data.map((item) => (
              <li key={item.id}>
                {item.children.length > 0 ? (
                  <div>
                    <h3 className="font-medium text-lg">{item.category}</h3>
                    <ul className="pl-4">
                      {item.children.map((child) => (
                        <li key={child.id}>
                          <NavLink
                            to={`/${child.link || "#"}`}
                            className="text-sm hover:text-blue-700"
                            onClick={toggleMenu}
                          >
                            {child.category}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <NavLink
                    to={`/${item.link}`}
                    className="text-base hover:text-blue-700"
                    onClick={toggleMenu}
                  >
                    {item.category}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Nav;
